import actions from '../store/actions';
import { getCookie, getDecryptedCookie } from './cookie';

const setAuthenticationPartner = async ctx => {
  if (ctx.req) {
    // server
    const token_cookie = getCookie('token_partner', ctx.req);
    if (token_cookie) {
      // if has token
      let user_cookie = getDecryptedCookie('user_partner', ctx.req);
      if (typeof user_cookie === 'string') {
        user_cookie = JSON.parse(decodeURIComponent(user_cookie));
      }
      // save in redux store
      ctx.store.dispatch(
        actions.reauthenticatePartner(token_cookie, user_cookie)
      );
    }
  }
};
export default setAuthenticationPartner;
