const getParameterObject = url => {
  try {
    url = decodeURIComponent(url);
  } catch (err) {
    url = decodeURIComponent(url.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
  }
  let params = {};
  if (url.indexOf('?') < 0) return params;
  params = url.substring(url.indexOf('?') + 1, url.length);
  params = params.split('&');
  let output = {};
  for (let i = 0; i < params.length; i++) {
    let key = params[i].split('=')[0];
    let value = params[i].split('=')[1];
    if (key.indexOf('[]') > -1) {
      // if param is array type
      key = key.replace('[]', '');
      if (!output[key]) {
        output[key] = [value];
      } else {
        output[key].push(value);
      }
    } else {
      // if param is string type
      output[key] = value;
    }
  }
  return output;
};

export default getParameterObject;
