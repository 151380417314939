import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import actions from '../store/actions';
import { getCookie } from '/utils/cookie';
import { setCookie } from 'nookies';
import fetch from 'isomorphic-unfetch';

const setBasicInfo = async ctx => {
  const { authentication } = ctx.store.getState();
  let params;
  if (authentication.token) {
    params = {
      method: 'GET',
      headers: {
        Authorization: 'bearer ' + authentication.token,
      },
    };
  }
  const callBasicInfo = await fetch(`${publicRuntimeConfig.API_PATH}/basic_info`, params);
  const basic_info = await callBasicInfo.json();
  const { currency_list, language_list, point } = basic_info;
  ctx.store.dispatch(actions.changeCurrencyInfo(currency_list));
  ctx.store.dispatch(actions.changeLanguageInfo(language_list));
  if (!isNaN(point)) {
    ctx.store.dispatch(actions.setSumPoint(point));
  }
  // set location
  if (ctx.req) {
    let tr_currency = getCookie('tr_currency', ctx.req);
    let tr_currency_source = getCookie('tr_currency_source', ctx.req);
    if (!(tr_currency && tr_currency_source)) {
      let country_code = 'US';
      tr_currency = 'USD';
      tr_currency_source = 'auto';
      let ip = ctx.req.headers['x-forwarded-for'];
      if (ip) {
        ip = ip.split(',')[0].trim();
        const callLocation = await fetch(`${publicRuntimeConfig.API_PATH}/ip-to-currency?ip=${ip}`);
        if (callLocation.status === 200) {
          const location = await callLocation.json();
          country_code = location.country_code || 'US';
          tr_currency = location.currency_code || 'USD';
        }
      }
      setCookie(ctx, 'tr_location', country_code, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
      setCookie(ctx, 'tr_currency', tr_currency, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
      setCookie(ctx, 'tr_currency_source', tr_currency_source, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
    }
    ctx.store.dispatch(
      actions.changeCurrency({
        tr_currency,
        tr_currency_source,
      }),
    );
  }
};
export default setBasicInfo;
