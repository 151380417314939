import Router from 'next/router';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import getParamsObject from '/utils/getParamsObject';
import removeQueries from '/utils/removeQueries';
import { getCookie, removeCookie } from './cookie';
import { setCookie } from 'nookies';
import fetch from 'isomorphic-unfetch';
import removeUrlParameter from '/utils/removeUrlParameter';

const setAdminAnalytics = async ctx => {
  let params = {};
  const { locale } = ctx;
  let full_url = '';
  let path_without_query = '';
  let url_params = ctx.asPath;
  const browser_regex = /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i;
  const bot_regex = /bot|googlebot|crawler|spider|robot|facebookexternalhit|crawling/i;
  if (ctx.req) {
    if (!(ctx.req.headers && ctx.req.headers['user-agent'])) return;
    const ua = ctx.req.headers['user-agent'];
    if (bot_regex.test(ua) || !browser_regex.test(ua)) return; // return if it's not browser
    let ip_address = getCookie('ip_address', ctx.req);
    if (!ip_address) {
      ip_address = ctx.req.headers['x-forwarded-for'];
      if (ip_address) {
        ip_address = ip_address.split(',')[0].trim();
        setCookie(ctx, 'ip_address', ip_address, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });
      }
    }
    // server
    let server_cookie_array = [];
    full_url = ctx.req.socket.encrypted
      ? 'https://'
      : 'http://' + ctx.req.headers.host + ctx.asPath;
    path_without_query = removeQueries(ctx.asPath);
    const query = getParamsObject(full_url);
    const age_for_6_month = `; Max-Age=${60 * 60 * 24 * 30 * 6}; Path=/`;
    const age_for_removal = `; Max-Age=0; Path=/`;
    if (path_without_query === '/' && query.hash) {
      // is home
      params['type'] = 'email';
      params['hash'] = query.hash;
      server_cookie_array.push(`tr_hc=${query.hash}${age_for_6_month}`);

      url_params = removeUrlParameter('hash', url_params);
    } else {
      if (query.ch) {
        // marketing
        params['type'] = 'marketing';
        params['channel_id'] = query.ch;
        server_cookie_array.push(`tr_ch=${query.ch}${age_for_6_month}`);
        if (query.label) params['label'] = query.label;
        if (query.link_id) {
          params['link_id'] = query.link_id;
          server_cookie_array.push(
            `tr_mkt_link=${query.link_id}${age_for_6_month}`
          );
        }
        if (params['referrer']) {
          server_cookie_array.push(
            `tr_mkt_source=${params['referrer']}${age_for_6_month}`
          );
        } else {
          server_cookie_array.push(`tr_mkt_source=null${age_for_removal}`);
        }
        server_cookie_array.push(
          `tr_mkt_landing=${full_url}${age_for_6_month}`
        );
        // remove affiliate cookies
        server_cookie_array.push(`tr_ref=null${age_for_removal}`);
        server_cookie_array.push(`tr_ref_ad=null${age_for_removal}`);
        server_cookie_array.push(`tr_ref_source=null${age_for_removal}`);
        server_cookie_array.push(`tr_ref_landing=null${age_for_removal}`);

        url_params = removeUrlParameter(['ch', 'link_id', 'label'], url_params);
      } else if (query.ref) {
        // affiliate
        params['type'] = 'affiliate';
        params['affiliate_id'] = query.ref;
        server_cookie_array.push(`tr_ref=${query.ref}${age_for_6_month}`);
        if (query.ad_id) {
          params['ad_id'] = query.ad_id;
          server_cookie_array.push(
            `tr_ref_ad=${query.ad_id}${age_for_6_month}`
          );
        }
        if (query.label) params['label'] = query.label;
        if (params['referrer']) {
          server_cookie_array.push(
            `tr_ref_source=${params['referrer']}${age_for_6_month}`
          );
        } else {
          server_cookie_array.push(`tr_ref_source=null${age_for_removal}`);
        }
        server_cookie_array.push(
          `tr_ref_landing=${full_url}${age_for_6_month}`
        );
        // remove marketing cookies
        server_cookie_array.push(`tr_ch=null${age_for_removal}`);
        server_cookie_array.push(`tr_mkt_link=null${age_for_removal}`);
        server_cookie_array.push(`tr_mkt_source=null${age_for_removal}`);
        server_cookie_array.push(`tr_mkt_landing=null${age_for_removal}`);

        url_params = removeUrlParameter(['ref', 'ad_id', 'label'], url_params);
      } else if (path_without_query === '/tway') {
        // affiliate_partner
        params['type'] = 'affiliate_company';
        if (query.affiliate_url) params['affiliate_url'] = 'tway';
        server_cookie_array.push(`tr_ref=twayair${age_for_6_month}`);
        if (params['referrer']) {
          server_cookie_array.push(
            `tr_ref_source=${params['referrer']}${age_for_6_month}`
          );
        } else {
          server_cookie_array.push(`tr_ref_source=null${age_for_removal}`);
        }
        server_cookie_array.push(
          `tr_ref_landing=${full_url}${age_for_6_month}`
        );
        // remove marketing and affiliateㄹ cookies
        server_cookie_array.push(`tr_ref_ad=null${age_for_removal}`);
        server_cookie_array.push(`tr_ch=null${age_for_removal}`);
        server_cookie_array.push(`tr_mkt_link=null${age_for_removal}`);
        server_cookie_array.push(`tr_mkt_source=null${age_for_removal}`);
        server_cookie_array.push(`tr_mkt_landing=null${age_for_removal}`);
      }
      if (params.type) {
        if (ip_address) params['ip_address'] = ip_address;
        params['original_url'] = full_url;
        const authentication = ctx.store.getState().authentication; // TODO - should be fixed. not working
        if (authentication.token) params['token'] = authentication.token;
        if (ctx.req.headers.referer)
          params['referrer'] = ctx.req.headers.referer; //  not working because next.js doesn't have referer like node.js
      }
    }
    if (params.type) {
      await fetch(`${publicRuntimeConfig.API_PATH}/analytics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params),
      });
      let redirect_params = {
        Location: (locale != 'en' ? `/${locale}` : '') + path_without_query + url_params,
      };
      if (server_cookie_array.length) {
        redirect_params['Set-Cookie'] = server_cookie_array;
      }
      if (path_without_query === '/tway') {
        ctx.res.setHeader('Set-Cookie', server_cookie_array);
      } else {
        ctx.res.writeHead(302, redirect_params);
        ctx.res.end();
      }
    }
  } else {
    const ua = window.navigator.userAgent;
    if (bot_regex.test(ua) || !browser_regex.test(ua)) return; // return if it's not browser
    // front
    full_url = window.location.origin + '/' + ctx.asPath;
    path_without_query = removeQueries(ctx.asPath);
    const query = getParamsObject(full_url);
    if (path_without_query === '/' && query.hash) {
      // is home
      params['type'] = 'email';
      params['hash'] = query.hash;
      setCookie('tr_hc', query.hash, 180);

      url_params = removeUrlParameter('hash', url_params);
    } else {
      if (query.ch) {
        // marketing
        params['type'] = 'marketing';
        params['channel_id'] = query.ch;
        setCookie('tr_ch', query.ch, 180);
        if (query.label) params['label'] = query.label;
        if (query.link_id) {
          params['link_id'] = query.link_id;
          setCookie('tr_mkt_link', query.link_id, 180);
        }
        if (params['referrer']) {
          setCookie('tr_mkt_source', params['referrer'], 180);
        } else {
          removeCookie('tr_mkt_source');
        }
        setCookie('tr_mkt_landing', full_url, 180);
        // remove affiliate cookies
        removeCookie(`tr_ref`);
        removeCookie(`tr_ref_ad`);
        removeCookie(`tr_ref_source`);
        removeCookie(`tr_ref_landing`);

        url_params = removeUrlParameter(['ch', 'link_id', 'label'], url_params);
      } else if (query.ref && query.ref !== 'TC0373') {
        // affiliate
        params['type'] = 'affiliate';
        params['affiliate_id'] = query.ref;
        setCookie('tr_ref', query.ref, 180);
        if (query.ad_id) {
          params['ad_id'] = query.ad_id;
          setCookie('tr_ref_ad', query.ad_id, 180);
        }
        if (query.label) params['label'] = query.label;
        if (params['referrer']) {
          setCookie('tr_ref_source', params['referrer'], 180);
        } else {
          removeCookie('tr_ref_source');
        }
        setCookie('tr_ref_landing', full_url, 180);
        // remove marketing cookies
        removeCookie(`tr_ch`);
        removeCookie(`tr_mkt_link`);
        removeCookie(`tr_mkt_source`);
        removeCookie(`tr_mkt_landing`);

        url_params = removeUrlParameter(['ref', 'ad_id', 'label'], url_params);
      } else if (
        getCookie('tr_ref') !== 'twayair' &&
        path_without_query === '/tway'
      ) {
        // affiliate_partner
        params['type'] = 'affiliate_company';
        if (query.affiliate_url) params['affiliate_url'] = 'tway';
        if (params['referrer']) {
          setCookie('tr_ref_source', params['referrer'], 180);
        } else {
          removeCookie('tr_ref_source');
        }
        setCookie('tr_ref_landing', full_url, 180);
        // remove marketing and affiliate cookies
        removeCookie(`tr_ref_ad`);
        removeCookie(`tr_ch`);
        removeCookie(`tr_mkt_link`);
        removeCookie(`tr_mkt_source`);
        removeCookie(`tr_mkt_landing`);
      }
      if (params.type) {
        if (getCookie('ip_address')) {
          params['ip_address'] = getCookie('ip_address');
        }
        params['original_url'] = full_url;
        const authentication = ctx.store.getState().authentication;
        if (authentication.token) params['token'] = authentication.token;
        if (document.referrer) params['referrer'] = document.referrer;
      }
    }
    if (params.type) {
      await fetch(`${publicRuntimeConfig.API_PATH}/analytics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(params),
      });
      if (path_without_query !== '/tway') {
        Router.push((locale != 'en' ? `/${locale}` : '') + path_without_query + url_params);
      }
    }
  }
};
export default setAdminAnalytics;
